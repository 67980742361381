import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faInstagram, faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import './css/Header.css'

const Header = () => {
    return (
        <header style={{ background: 'linear-gradient(90deg, rgb(247, 168, 10) 0%, rgb(247, 168, 10) 1.9%, rgb(250, 94, 8) 5.5%, rgba(5, 5, 5, 1) 7%, rgb(5, 5, 5) 93.5%, rgb(33, 138, 249) 96%, rgb(72, 199, 225) 98.1%, rgb(72, 199, 225) 100%)', marginBottom: '0', backgroundAttachment: 'fixed' }}>
            <div className="sectionOverlay" id="headerOverlay"></div>
            <div id="outerHeader" className="noCustomElement" style={{ opacity: 1 }}>
                <div id="headerContainer" className="noCustomElement">
                    <div id="headerContent" className="noCustomElement" style={{ paddingTop: "80px" }}>
                        <div className="row noCustomElement">
                            <div className="col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1 noCustomElement">
                                <div id="headerTitleWrap">
                                    <h1 id="headerTitle" className="headerTitles" style={{ textAlign: 'center', marginTop: '0', marginBottom: '15px', fontSize: '47px' }}>Novalis Talent<br />Management</h1>
                                </div>
                            </div>
                        </div>
                        <div id="iconContainer" style={{ marginLeft: '0' }}>
                            <FontAwesomeIcon id="facebookHeader" className="headerIcon" icon={faTwitch} style={{ display: 'none' }} onClick={() => window.location.href = 'https://twitch.tv/NovalisTalent'} />
                            <FontAwesomeIcon id="instagramHeader" className="headerIcon" icon={faInstagram} style={{ display: 'none' }} onClick={() => window.location.href = 'https://www.instagram.com/NovalisTalent/'} />
                            <FontAwesomeIcon id="twitterHeader" className="headerIcon" icon={faTwitter} onClick={() => window.location.href = 'https://twitter.com/NovalisTalent'} />
                            <FontAwesomeIcon id="linkedinHeader" className="headerIcon" icon={faDiscord} style={{ display: 'none' }} />
                        </div>
                        <div id="bigAboutWrap" className="row noCustomElement centered">
                            <div className="col-sm-6 noCustomElement">
                                <div className="innerGridTable noCustomElement">
                                    <div className="innerTableContent noCustomElement">
                                        <br /> <br />
                                        <div id="aboutTitleWrap">
                                            <h2 id="aboutTitle" className="aboutTitles" style={{ textAlign: 'center', paddingLeft: '0', margin: '0 42px 8px 46px' }}>Your Success is Our Priority<br /><br /></h2>
                                        </div>
                                        <div id="aboutParagraphWrap">
                                            <p id="aboutParagraph1" className="aboutParagraph">
                                                Novalis specializes in managing VTubers and developing their careers, skills, and communities. We work closely with our clients to help them grow and to improve their online presence. <br /><br />
                                                Whether you are just starting out or looking to take your career to the next level, we're here to help you achieve your goals.
                                            </p>
                                        </div>
                                        <br /> <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
