// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation if you're using react-router
import './css/Navbar.css';

const Navbar = () => {
  return (
    <nav id="mainMenu" role="navigation" className="navbar navbar-fixed-top">
      <div className="container noCustomElement">
        <div className="navbar-header noCustomElement">
          <button
            type="button"
            className="navbar-toggle menuButton"
            data-toggle="collapse"
            data-target="#navigationBar"
            aria-expanded="true"
          >
            <span id="menuSpanText">Menu</span>
            <i className="openMenuIcon fa fa-bars"></i>
          </button>
          <Link
            id="menuTitle"
            className="navbar-brand page-scroll text-custom-id-jxeP19fOZHDPYlAJ7BhCQYAc8dAeLL8uCIisZ6pS"
            style={{ paddingTop: '0px' }}
            to="/"
          >
            <img
              src="img/logo.png"
              alt="Novalis Talent Logo"
              className="logo-image"
              style={{ marginLeft: '0px', marginRight: '0px', marginTop: '0px' }}
            />
            <br />
            <span className="logo-text invisible-text"></span>
          </Link>
        </div>
        <div
          id="navigationBar"
          className="navbar-collapse noCustomElement collapse"
        >
          <ul id="menuButtons" className="nav navbar-nav navbar-right">
            <ul id="menuItems" className="nav navbar-nav navbar-right">
              <li className="menuItemLi " style={{ display: 'none' }}>
                <Link id="featuresMenuItem" className="menuItemA" to="#features">
                  Services
                </Link>
              </li>
              <li className="menuItemLi">
                <Link id="talentsMenuItem" className="menuItemA" to="#talents">
                  Talents
                </Link>
              </li>
              <li className="menuItemLi">
                <Link id="contactMenuItem" className="menuItemA" to="#contact">
                  Contact
                </Link>
              </li>
              <li className="menuItemLi" style={{ display: 'none' }}>
                <Link id="merchMenuItem" className="menuItemA" to="/merch">
                  Merch
                </Link>
              </li>
              <li className="menuItemLi">
                <a
                  id="ethicsMenuItem"
                  className="menuItemA"
                  href="https://www.talentmanagers.org/code-of-ethics"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code of Ethics
                </a>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
