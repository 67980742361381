// src/components/Footer.js
import React from 'react';
import './css/Footer.css';

const Footer = () => {
    return (
        <div id="footer">
            <h4
                className="pageFooter text-custom-id-BLErEI590wD7Nugj9WDlTIgZ1F85MDgl7Ct7NTEX"
                id="footerFour"
                style={{
                    textAlign: 'left',
                    fontFamily: 'Lato, sans-serif',
                    marginLeft: 'auto',
                    paddingTop: '20px',
                    paddingBottom: '15px',
                    maxWidth: '90%',
                    background: 'rgba(50, 50, 50, 0)',
                }}
            >
                © 2024, Novalis Talent Management
            </h4>
        </div>
    );
};

export default Footer;
