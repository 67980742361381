// src/pages/HomePage.js
import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Talents from '../components/Talents';
import Footer from '../components/Footer';
import Contact from '../components/Contact';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Talents />
      <Contact />
      <Footer />
    </div>
  );
};

export default HomePage;
