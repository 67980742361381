// src/components/About.js
import React from 'react';

const AboutTeam = () => {
  return (
    <section id="about" className="about-section bg-light">
      <div className="container">
        <h2>Meet the Founder</h2>
        <div className="about">
          <img src="/img/your-image.jpg" alt="Your Name" className="about-image" />
          <div className="about-content">
            <h3>Your Name</h3>
            <p>
              Founder of Novalis Talent Management, with extensive experience in the industry. My mission
              is to help VTubers and creators build their careers by providing expert guidance and a
              dedicated management team. Let’s create something great together!
            </p>
            <p>
              <a href="https://linkedin.com/in/your-profile" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTeam;
