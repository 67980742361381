import React, { useCallback, useEffect, useState } from 'react';
import { fetchTalentList } from '../services/talentService';
import './css/Talents.css'; // Include your styles here

const Talents = () => {
    const [currentPosition, setCurrentPosition] = useState(0);
    const [numVisibleColumns, setNumVisibleColumns] = useState(0);
    const [talents, setTalents] = useState([]);
    const [talentsWidth, setTalentsWidth] = useState(260);

    const updateColumnVisibility = useCallback(() => {
        const talentsElements = document.querySelectorAll('.team-member');
        var width = Array.from(talentsElements)[0].scrollWidth;
        if ( width === 0) width = 260;
        setTalentsWidth(width);
        talentsElements.forEach((member, index) => {
            const columnIndex = (currentPosition + index) % talents.length;
            const isVisible = columnIndex < numVisibleColumns;
            member.style.order = columnIndex;
            member.style.display = isVisible ? 'flex' : 'none';
        });
    }, [currentPosition, numVisibleColumns, talents.length]);

    useEffect(() =>  {
        async function fetchData() {
            fetchTalentList().then((data) => {
                setTalents(data);
            });
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        const handleResize = () => {
            const container = document.querySelector('.team-members-container');
            if (!container) return;
            const maxWidth = parseFloat(getComputedStyle(container).maxWidth);
            
            const members = document.querySelector('.team-members');
            if (!members) return;
            const gap = parseFloat(getComputedStyle(members).gap);
            const teamMemberInfoWrap = document.querySelector('.teamMemberInfoWrap');
            if (!teamMemberInfoWrap) return;
            const boxShadow = getComputedStyle(teamMemberInfoWrap).boxShadow;
            const blurRadius = parseFloat(boxShadow.split(' ')[5]);

            const scrollButton = document.querySelector('.scroll-button');
            if (!scrollButton) return;
            const buttonWidth = parseFloat(getComputedStyle(scrollButton).width);

            const nextButton = document.querySelector('.next-button');
            if (!nextButton) return;
            let buttonMargin = parseFloat(getComputedStyle(nextButton).marginLeft);

            const availableWidth = ((window.innerWidth * maxWidth / 100 + gap) - (buttonWidth * 3 + buttonMargin * 2 + blurRadius * 2));
            const numVisible = Math.max(Math.floor(availableWidth / (talentsWidth + gap)), 1)
            setNumVisibleColumns(numVisible);
            updateColumnVisibility();
        };

        // Fetch talents when the component mounts

        window.addEventListener('resize', handleResize);
        handleResize(); // Call it once on load

        return () => window.removeEventListener('resize', handleResize);
    }, [updateColumnVisibility, talentsWidth]);

    const scrollToLeft = () => {
        setCurrentPosition((prev) => (prev + 1) % talents.length);
        updateColumnVisibility();
    };

    const scrollToRight = () => {
        setCurrentPosition((prev) => (prev - 1 + talents.length) % talents.length);
        updateColumnVisibility();
    };

    return (
        <section id="team">
            <div className="sectionOverlay" id="teamOverlay" style={{}}></div>
            <div id="outerTeam" className="outerSection noCustomElement" style={{ opacity: 1 }}>
                <div
                    id="teamContainer"
                    className="sectionContainer teamImageBg noCustomElement"
                    style={{
                        background: 'linear-gradient(90deg, rgb(247, 168, 10) 0%, rgb(247, 168, 10) 1.9%, rgb(250, 94, 8) 5.5%, rgb(5, 5, 5) 7%, rgb(5, 5, 5) 93.5%, rgb(33, 138, 249) 96%, rgb(72, 199, 225) 98.1%, rgb(72, 199, 225) 100%)',
                    }}
                >
                    <div id="teamContent" className="sectionContent container-fluid noCustomElement">
                        <div id="teamTitleWrap" className="noCustomElement">
                            <div className="noCustomElement">
                                <div id="teamTitleWrap">
                                    <h2 id="teamTitle" className="teamTitles">Our Talents</h2>
                                </div>
                                <div className="team-members-container">
                                    <button className="scroll-button prev-button" onClick={scrollToLeft}>&lt;</button>
                                    <div className="team-members">
                                        {talents.map((member, index) => (
                                            <div className="team-member" key={index} style={{ display: 'flex' }}>
                                                <div className="teamMemberImage">
                                                    <img className="teamMemberImages" src={member.image} alt={member.name} style={{ marginBottom: '13px' }} />
                                                </div>
                                                <div className="teamMemberInfoWrap greyBackground noCustomElement">
                                                    <div className="teamMemberNameWrap">
                                                        <h3 className="teamMemberName">{member.name}</h3>
                                                    </div>
                                                    <div className="teamMemberHrWrap">{member.position}</div>
                                                    <div className="teamMemberTextWrap">
                                                        <p className="teamMemberText">{member.description}</p>
                                                    </div>
                                                    <div id="iconContainer" style={{ paddingBottom: '10px' }}>
                                                        {member.socialLinks.twitch && (
                                                            <i className="headerIcon fab fa-twitch" onClick={() => window.location.href = member.socialLinks.twitch} style={{ padding: '5px' }}></i>
                                                        )}
                                                        {member.socialLinks.instagram && (
                                                            <i className="headerIcon fab fa-instagram" onClick={() => window.location.href = member.socialLinks.instagram} style={{ padding: '5px' }}></i>
                                                        )}
                                                        {member.socialLinks.twitter && (
                                                            <i className="headerIcon fab fa-twitter" onClick={() => window.location.href = member.socialLinks.twitter} style={{ padding: '5px' }}></i>
                                                        )}
                                                        {member.socialLinks.tiktok && (
                                                            <i className="headerIcon fab fa-tiktok" onClick={() => window.location.href = member.socialLinks.tiktok} style={{ padding: '5px' }}></i>
                                                        )}
                                                        {member.socialLinks.discord && (
                                                            <i className="headerIcon fab fa-discord" onClick={() => window.location.href = member.socialLinks.discord} style={{ padding: '5px' }}></i>
                                                        )}
                                                        {member.socialLinks.emailimg && (
                                                            <img className="customImage" alt='Email Address' src={member.socialLinks.emailimg} onClick={() => window.location.href = member.socialLinks.mailto} style={{ maxWidth: '210px', margin: '0px', border: '0px' }} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="scroll-button next-button" onClick={scrollToRight}>&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Talents;
