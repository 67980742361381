// src/pages/AboutPage.js
import React from 'react';
import About from '../components/About';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (
    <div>
      <About />
      <Footer />
    </div>
  );
};

export default AboutPage;
