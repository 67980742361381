import React from 'react';
import './css/Contact.css';

const Contact = () => {
    const handleIconClick = (url) => {
        window.location.href = url;
    };

    return (
        <section id="contact">
            <div className="sectionOverlay" id="contactOverlay"></div>
            <div id="outerContact" className="outerSection noCustomElement" style={{ opacity: 1 }}>
                <div
                    id="contactContainer"
                    className="sectionContainer contactImageBg noCustomElement customImageBg"
                    style={{
                        background: 'linear-gradient(90deg, rgb(247, 168, 10) 0%, rgb(247, 168, 10) 1.9%, rgb(250, 94, 8) 5.5%, rgb(5, 5, 5) 7%, rgb(5, 5, 5) 93.5%, rgb(33, 138, 249) 96%, rgb(72, 199, 225) 98.1%, rgb(72, 199, 225) 100%)',
                        marginBottom: '0px',
                    }}
                >
                    <div id="contactWrap" className="centered noCustomElement">
                        <div id="contactContent" className="sectionContent container-fluid noCustomElement">
                            <div>
                                <br />
                                <h3 className="text-custom-id-TtK3Ohxg8es69toAuifqaBptUUttkn2nHzuNhuOm" altonclick="null" althref="null">
                                    Contact<br />
                                </h3>
                                <i
                                    id="facebookHeader"
                                    className="headerIcon fab fa-twitch"
                                    style={{ display: 'none' }}
                                    althref="null"
                                    onClick={() => handleIconClick('https://twitch.tv/NovalisTalent')}
                                ></i>
                                <i
                                    id="instagramHeader"
                                    className="headerIcon fab fa-instagram"
                                    style={{ display: 'none', paddingBottom: '30px' }}
                                    althref="null"
                                    onClick={() => handleIconClick('https://www.instagram.com/NovalisTalent/')}
                                ></i>
                                <i
                                    id="twitterHeader"
                                    className="headerIcon fab fa-twitter"
                                    style={{ paddingBottom: '30px' }}
                                    althref="null"
                                    onClick={() => handleIconClick('https://twitter.com/NovalisTalent')}
                                ></i>
                                <i
                                    id="discordHeader"
                                    className="headerIcon fab fa-discord"
                                    style={{ display: 'none' }}
                                    altonclick="null"
                                    althref="null"
                                ></i>
                            </div>
                            <div className="row noCustomElement centered">
                                <div id="contactTop" className="row greyBackground noCustomElement" style={{ boxShadow: '0px 0 8px 1px white' }}>
                                    <div className="col-sm-12 noCustomElement" style={{ padding: '0px' }}>
                                        <div
                                            id="contactTitleSubWrap"
                                            className="contactTitleSub contactTitleSubInline"
                                            onClick={() => handleIconClick('mailto:dcard@NovalisTalent.com')}
                                            style={{ margin: '5px', padding: '0px' }}
                                        >                                          
                                            <img
                                                src="img/managerEmail.png"
                                                className="customImage"
                                                style={{ maxWidth: '210px', margin: '0px', border: '0px' }}
                                                altonclick="null"
                                                althref="null"
                                                alt='Email Address'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
