// src/services/talentService.js
export const fetchTalentList = async () => {
  try {
    const response = await fetch('/data/profiles.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching talent data:', error);
    return [];
  }
};
